import * as _ from "lodash";
import * as xlsx from "xlsx";
import kmlParser from 'js-kml-parser';

export const getRepZones = (activeZones, rep) => {
  let _zones = [];

  if (rep.Territories == "All") {
    _.forEach(activeZones, z => {
      _zones.push(z["Sub wholesaler Id"])
    });

  } else {
    let repZones = rep.Territories?.split("|");

    _.forEach(repZones, repZone => {

      let isRegion = repZone.split(">").length == 1;
      let isBranch = repZone.split(">").length == 2;

      if (isRegion) {

        let allZonesUnderRegion = _.filter(activeZones, { "Wholesaler Id": repZone })

        _.forEach(allZonesUnderRegion, z => {
          _zones.push(z["Sub wholesaler Id"])
        });
      }
      else if (isBranch) {
        let branchName = repZone.split(">").pop();

        let allZonesUnderBranch = _.filter(activeZones, { "Branch Office Id": branchName })

        _.forEach(allZonesUnderBranch, z => {
          _zones.push(z["Sub wholesaler Id"])
        });
      }
      else {
        _zones.push(repZone.split(">").pop())
      }
    })
  }

  return _zones;
}

export const arrayBufferToString = (buffer) => {
  var arr = new Uint8Array(buffer);
  var str = String.fromCharCode.apply(String, arr);
  if (/[\u0080-\uffff]/.test(str)) {
    throw new Error("this string seems to contain (still encoded) multibytes");
  }
  return str;
}

export const readUploadedKMLFile = (fileBlob) => {

  return new Promise((resolve, reject) => {
    try {
      if (fileBlob) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const geoJson = kmlParser.toGeoJson(reader.result);
          resolve(geoJson);
        }
        reader.readAsText(fileBlob);
      }
    } catch (error) {
      reject(Error(error));
    }
  });
}

export const readUploadedExcelFile = (fileBlob) => {

  return new Promise((resolve, reject) => {
    try {
      if (fileBlob) {
        const reader = new FileReader();
        reader.onload = (e) => {
          //debugger;
          const data = e.target.result;

          let workbook;
          let format = "xlsx";

          switch (format) {
            case "html":
              const wrapper = document.createElement('div');

              wrapper.innerHTML = arrayBufferToString(data);

              const table = wrapper.getElementsByTagName('table');

              workbook = xlsx.utils.table_to_book(table, {
                sheet: "Sheet1"
              });

              //dispose the element
              break;
            case "xlsx":
              workbook = xlsx.read(data, {
                type: "array"
              });
              break;
          }

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet, {
            defval: null
          });
          resolve(json);
        }
        reader.readAsArrayBuffer(fileBlob);
      }
    } catch (error) {
      reject(Error(error));
    }
  });
}

export const makeWorkbook = (worksheetObjectArray, addDefaultFilter) => {

  try {
    const workbook = xlsx.utils.book_new();
    if (worksheetObjectArray && _.isArray(worksheetObjectArray)) {
      _.forEach(worksheetObjectArray, worksheetObject => {

        try {

          const worksheet = xlsx.utils.json_to_sheet(worksheetObject.jsonData);

          if (addDefaultFilter) {
            let decoded_range = xlsx.utils.decode_range(worksheet['!ref']);

            let encoded_range = xlsx.utils.encode_range(decoded_range.s, decoded_range.e);

            worksheet['!autofilter'] = { ref: encoded_range }
          }

          xlsx.utils.book_append_sheet(workbook, worksheet, worksheetObject.worksheetName);

        }
        catch (e) {
          console.log("Error: worksheetObject.jsonData", e, worksheetObject.jsonData, worksheetObjectArray);
        }
      });
    }
    return workbook;
  }
  catch (ex) {
    console.log("Error: makeWorkbook", ex);
  }
}

export const downloadExcel = (data, fileName, format, addTimeStamp) => {
  //const worksheet = xlsx.utils.json_to_sheet(data);
  //const workbook = xlsx.utils.book_new();
  //xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  debugger;

  if (addTimeStamp) {
    var dNow = new Date();
    let s = " (" + (dNow.getMonth() + 1) + '-' + dNow.getDate() + '-' + dNow.getFullYear() + ' ' + String(dNow.getHours()).padStart(2, "0") + '꞉' + String(dNow.getMinutes()).padStart(2, "0") + ")";
    fileName += s;
  }

  const workbook = makeWorkbook(data, true);
  debugger;

  if (!workbook) {
    console.log("WORKBOOK ERROR", data, fileName, format);
    return;
  }
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binar y" });

  let bookType = format || "xlsx"

  xlsx.writeFile(workbook, fileName + "." + bookType, { bookType: bookType });
}

export const getDaysArray = (s, e) => {
  for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
    a.push(new Date(d));
  }
  return a;
}

export const removeUnnecessaryColumnsInRepslyPlaces = (places) => {

  if (places && places.length > 0) {
    places.forEach(function (x) {
      delete x["Last check-in date"];
      delete x["Representative ID"];
      delete x["Representative name"];
    });
  }
}

export const dateFormatter = (dateToFormat) => {
  return (dateToFormat.getMonth() + 1) + '/' + dateToFormat.getDate() + '/' + dateToFormat.getFullYear();
}

export const filterByZoneName = (allStores, zoneNames) => {

  //var filtered = _.filter(uniqueStores, o => {
  //  return _.includes(zoneNames, o["Territory"]);
  //});
  debugger;

  let filtered = _.filter(allStores, (v) => _.includes(zoneNames, v.Territory?.split(">").pop()));


  // let filtered = _.filter(allStores, o => {
  //   return _.some(zoneNames, o["Territory"].split(">")[1])
  // });

  // let simSorted = _.orderBy(filtered, [(o) => {
  //   return (o["Sim Delivered"] == "No" && o["Type"] == "DD")
  // }], ['desc']);


  //sort addresses by distance
  //todo: check visit status file, get missed schedules and put them on the front
  let dateSorted = _.orderBy(filtered, [(o) => {
    return (Date.parse(o["Last check-in date"]) || '')
  }, "Gps latitude", "Gps longitude"]);
  // }, "ZIP", "Street Address"]);


  return dateSorted;
}

//https://stackoverflow.com/questions/2861272/polygon-area-calculation-using-latitude-and-longitude-generated-from-cartesian-sp
export const calculatePolygonArea = (coordinates) => {
  let area = 0;

  if (coordinates.length > 2) {
    for (var i = 0; i < coordinates.length - 1; i++) {
      let p1 = coordinates[i];
      let p2 = coordinates[i + 1];

      area += convertToRadian(p2[0] - p1[0]) * (2 + Math.sin(convertToRadian(p1[1])) + Math.sin(convertToRadian(p2[1])));
    }

    area = area * 6378137 * 6378137 / 2;
  }

  return Math.abs(area) * 0.00000038610;
}

export const convertToRadian = (input) => {
  return input * Math.PI / 180;
}
