import React, { useRef, useState } from "react";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadFile from '@mui/icons-material/UploadFile';
import CheckCircle from '@mui/icons-material/CheckCircle';


const FileUploader = (props) => {
    const [dragActive, setDragActive] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let fileExt = e.dataTransfer.files[0].name.split('.').pop();

            let acceptedExtensions = props.accept.split(", ");

            let fileIsAccepted = acceptedExtensions.includes("." + fileExt);

            if (fileIsAccepted) {
                fileHandler(e.dataTransfer.files[0]);
            }
            else {
                alert("This file type is not supported on this field!\n\nAccepted Filetype(s): " + props.accept);
            }
        }
    };

    const fileHandler = (e) => {
        if (props.fileHandler) {
            setLoading(true);
            props.fileHandler(e).then(c => {
                setLoading(false);
            });
        }
        else {
            props.setFile(e).then(c => {
                setLoading(false);
            });
        }
    }

    const setFile = (e) => {
        fileHandler(e.target.files[0])
    }

    const buttonStyle = {
        dragActive: {
            backgroundColor: "#C35000",
            border: "1px dashed"
        }
    }

    return (
        <>
            <LoadingButton
            
                variant={"outlined"}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                component="label"
                color={"primary"}
                loading={loading}
                loadingPosition="start"
                startIcon={props.file ? <CheckCircle /> : <UploadFile />}
                disabled={props.disabled || props.file}
                sx={props.file && {
                    "&.Mui-disabled": {
                        background: "green",
                        color: "#c0c0c0"
                    }
                }}

                style={dragActive ? buttonStyle.dragActive : null}
            >{props.label}
                <input hidden type="file" accept={props.accept} onChange={(e) => { setFile(e); }} />
            </LoadingButton>

            {props.file &&
                <span>
                    &nbsp;

                    <IconButton onClick={(e) => { props.setFile(null); }} aria-label="delete">
                        <DeleteForeverIcon />
                    </IconButton>
                </span>
            }
        </>
    );
}

export default FileUploader
