import React, { useRef, useState } from "react";
import * as _ from "lodash";
import Box from '@mui/material/Box';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import Schedule from '@mui/icons-material/Schedule';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';


import DateRange from '@mui/icons-material/DateRange';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {
    downloadExcel,
    getDaysArray,
    readUploadedExcelFile,
    getRepZones,
    removeUnnecessaryColumnsInRepslyPlaces,
    readUploadedKMLFile,
    filterByZoneName,
    dateFormatter,
    calculatePolygonArea
} from "../../components/helpers/helpers"

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import ScheduleMap from "../schedule-map/scheduleMap";

const options = {
    //weekday: "short",
    //year: "numeric",
    month: "short",
    day: "numeric"
};
const styles = {
    statusIcon: {
        backgroundColor: "orange",
        borderRadius: 25,
        width: "20px",
        height: "20px",
    },
    statusIconNotVisitedSinceLongtime: {
        border: "3px solid red"

    },
    statusIconScheduled: {
        backgroundColor: "red",
    },
    statusIconScheduledAnotherDay: {
        backgroundColor: "pink",
    },

};
let repByZone = [];

const ScheduleManager = (props) => {

    const [calendarPickerOpen, setCalendarPickerOpen] = React.useState(false);
    const [calendarPickerDates, setCalendarPickerDates] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    const [calendarPickerUserDetails, setCalendarPickerUserDetails] = React.useState({});
    const [calendarPickerZoneDetails, setCalendarPickerZoneDetails] = React.useState({});
    const [calendarPickerStoreDetails, setCalendarPickerStoreDetails] = React.useState({});

    const [repAbsenceReason, setRepAbsenceReason] = React.useState("");
    const [zoneFullName, setZoneFullName] = React.useState();
    const [selectedRegion, setSelectedRegion] = React.useState([]);
    const [selectedOffices, setSelectedOffices] = React.useState([]);

    const [userDetailsList, setUserDetailsList] = React.useState([]);
    const [expandedAccordionId, setExpandedAccordionId] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedAccordionId(isExpanded ? panel : false);
    };

    const dateFormatter = (date) => {
        console.log("dateFormatter");
        return date.toLocaleDateString("en", options);
    }

    const pickADate = (user, zone, startDate, endDate) => {
        //debugger;
        setCalendarPickerUserDetails(user);
        setCalendarPickerZoneDetails(zone);

        let fullZoneName = zone["Wholesaler Id"] + ">" + zone["Branch Office Id"] + ">" + zone["Sub wholesaler Id"];


        setZoneFullName(fullZoneName);

        setCalendarPickerDates({
            startDate: startDate,
            endDate: endDate
        });

        setCalendarPickerOpen(true);
    }

    const saveRepPersonalDatesAndClosePicker = () => {

        setCalendarPickerOpen(false);
    }

    const getRep = (zone) => {
        //debugger;
        let zoneName = zone["Sub wholesaler Id"];
        return _.filter(props.users, user => {
            return user.Designation == "Sales Marketing Rep" && user.Territories.indexOf(zoneName) > -1
        });
    }

    const getLead = (zone) => {
        let zoneName = zone["Sub wholesaler Id"];

        return _.filter(props.users, user => {
            return user.Designation == "Team Lead" && user.Territories.indexOf(zoneName) > -1
        });
    }

    const onSelectCoveringPerson = (event, zoneName) => {
        event.preventDefault();
        event.stopPropagation();

        //debugger;
        console.log("event", event);
        console.log("repByZone", repByZone);

        if (event.target.value) {

            const alreadyMapped = _.some(repByZone, { ID: event.target.value });

            if (alreadyMapped) {
                //debugger;

                event.preventDefault();
                event.stopPropagation();
                alert("This person already covering a zone");
            }
            else {

                repByZone.push({
                    zone: zoneName,
                    ID: event.target.value
                })
            }
        } else {

            _.remove(repByZone, { zone: zoneName });
        }


    }

    const ZoneRow = ({ users, zone, startDate, endDate }) => {

        const reps = getRep(zone);

        const sharedZone = reps.length > 1;
        const vacantZone = reps.length == 0;
        const zoneName = zone["Sub wholesaler Id"];

        const marketingRep = _.first(reps);
        const teamLead = _.first(getLead(zone));

        if (sharedZone) {

        } else if (vacantZone) {

        }
        else {
            repByZone.push({
                zone: zoneName,
                ID: marketingRep.ID
            })
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                    <Box>
                        {zoneName}
                    </Box>

                    <Box>

                        {sharedZone ?
                            <Box>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel>Covering Person</InputLabel>
                                    <Select
                                        onChange={event => onSelectCoveringPerson(event, zoneName)}
                                        label="Covering Person"
                                    >
                                        {reps.map((_rep) => (
                                            <MenuItem value={_rep.ID}>
                                                {_rep.Name}
                                            </MenuItem>
                                        ))}

                                        {teamLead && <MenuItem value={teamLead.ID}>{teamLead.Name} (Team Lead)</MenuItem>}

                                    </Select>
                                </FormControl>
                            </Box>
                            :
                            vacantZone ? teamLead && <Box>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 150, height: 50 }}>
                                    <InputLabel>Covering Lead</InputLabel>
                                    <Select
                                        label="Covering Lead"
                                        onChange={event => onSelectCoveringPerson(event, zoneName)}
                                    >
                                        <MenuItem value={""}>
                                            <em>- None -</em>
                                        </MenuItem>
                                        <MenuItem value={teamLead.ID}>{teamLead.Name} (Team Lead)</MenuItem>

                                    </Select>
                                </FormControl>


                            </Box>
                                :
                                <Box>
                                    <Chip icon={<PersonOutlineOutlined />} label={marketingRep?.Name} variant="outlined" />
                                </Box>
                        }
                    </Box>
                    <Box>

                        <Chip icon={<DateRange />}
                            label={"date"}
                            onClick={() => pickADate(marketingRep, zone, startDate, endDate)} />

                    </Box>
                </Box>



            </Box>

        )
    }

    const ZoneList = (props) => {

        console.log("ZoneList");

        return (<>
            {props.activeZones && props.activeZones
                .map((zone, index) => (

                    <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                        <Box>
                            <Checkbox />
                        </Box>
                        <ZoneRow zone={zone} users={props.users} startDate={props.startDate} endDate={props.endDate} />
                    </Box>
                ))
            }
        </>)
    }

    const StoreSelected = (store, personId) => {

        console.log("ScheduleManager > StoreSelected", store.ID, personId);

        let theStore = _.find(props.places, { ID: store.ID })

        //theStore.selected = !theStore.selected;

        props.onUpdate(theStore);
    }


    return (

        <>
            {<div>

                <Dialog
                    open={calendarPickerOpen}
                    fullScreen
                    onClose={saveRepPersonalDatesAndClosePicker}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/* <DialogTitle id="alert-dialog-title">
                        {"Calendar"} of {calendarPickerZoneDetails["Sub wholesaler Id"]} - ({calendarPickerUserDetails?.Name})
                    </DialogTitle> */}

                    <DialogContent>

                        <div>

                            <span>DD Store:</span> <div style={styles.statusIcon}></div>

                            <span>Not Visited Recently:</span> <div style={{ ...styles.statusIcon, ...styles.statusIconNotVisitedSinceLongtime }}></div>

                            <span>Scheduled Store:</span> <div style={{ ...styles.statusIcon, ...styles.statusIconScheduled }}></div>

                            <span>Scheduled to Another Day:</span> <div style={{ ...styles.statusIcon, ...styles.statusIconScheduledAnotherDay }}></div>
                      
                        </div>


                        <DialogContentText component={'div'}>

                            {props.startDate && props.endDate && getDaysArray(props.startDate, props.endDate).map((day) => (
                                <Accordion
                                    key={day.toISOString()}
                                    expanded={expandedAccordionId == "panel-" + day.toISOString()}
                                    onChange={handleChange("panel-" + day.toISOString())}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={"panel-" + day.toISOString()}
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }} component={'span'}>
                                            {day.toLocaleDateString()}
                                            {" — "}
                                            <i>{day.toLocaleString('en-us', { weekday: 'long' })}'s Route</i>
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary' }} component={'span'}>
                                            {zoneFullName && zoneFullName.split(">").pop()}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <ScheduleMap
                                            selectedZoneName={zoneFullName}
                                            storeList={props.places}
                                            onSelected={StoreSelected}
                                            day={day}
                                        />

                                    </AccordionDetails>
                                </Accordion>

                            ))}

                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={e => { setCalendarPickerOpen(false) }}>Cancel</Button>

                        <Button onClick={() => saveRepPersonalDatesAndClosePicker()} autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>




            </div>}

            <Divider />

            Logic:

            <FormControl sx={{ m: 1, minWidth: 150 }}>

                <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} InputLabelProps={{ shrink: true }} label="Daily Visit Count" defaultValue="8" />




            </FormControl>

            <ZoneList activeZones={props.activeZones} users={props.users} startDate={props.startDate} endDate={props.endDate} />

        </>
    );
}

export default ScheduleManager
