import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Main from "./screens/main/main"
import ZoneDetails from './screens/zone-details/zone-details';

function App() {

  return (
    <Router>
      <div className="App">
        {/* <ul className="App-header">
          <li>
            <Link to='${process.env.PUBLIC_URL}/'>Main</Link>
          </li>
          <li>
            <Link to="/zone-details">Zone Details</Link>
          </li>
        </ul> */}
        <Routes>
          <Route exact path='/' element={< Main />}></Route>
          <Route exact path='/zone-details/:zoneName' element={< ZoneDetails />}></Route>
        </Routes>
      </div>  
    </Router>
  );
}

export default App;