import React, { useState } from 'react';
import * as xlsx from "xlsx";
import * as _ from "lodash";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Calendar from 'react-calendar';

import {
  downloadExcel,
  getDaysArray,
  readUploadedExcelFile,
  getRepZones,
  removeUnnecessaryColumnsInRepslyPlaces,
  readUploadedKMLFile,
  filterByZoneName,
  dateFormatter,
  calculatePolygonArea
} from "../../components/helpers/helpers"

import "./main.css"

import CustomFileUploader from "../../components/fileUploader/fileUploader"
import ScheduleManager from "../../components/schedule-manager/scheduleManager"
/*
MUI
*/
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Download from '@mui/icons-material/Download';
import Box from '@mui/material/Box';


import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

var classifyPoint = require("robust-point-in-polygon")

class Places extends Array {

  constructor(x) {
    super(x);
  }

  currentIndex = -1;
  next() {
    return this[++this.currentIndex % this.length];
  }
}


const prepareReportAndDownloadSchedule = (representatives, storeCount, startDate, endDate, isDebug, isIndividual) => {
  var schedule = [];
  console.log("prepareReportAndDownload", "storeCount", storeCount, "startDate", startDate.toLocaleDateString(), "endDate", endDate.toLocaleDateString(), "isDebug", isDebug);

  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  debugger;
  var activeHotspots = _.filter(representatives, x => x["Note"] == "Sales Marketing Rep" && x.Active == "True");

  console.log("hotspots", hotspots);

  // var hotspots = _.filter(activeHotspots, function (o) {
  //   return o.Territories.split(', ').length == 1
  // });
  var hotspots = activeHotspots;
  console.log("hotspots", hotspots);

  hotspots.forEach(hotspot => {
    let hotspotsSchedule = [];
    console.log("hotspot", hotspot);

    const _days = getDaysArray(startDate, endDate);

    let _places = _.uniqBy(filterByZoneName(hotspot.Territories.split("|")), "ID");

    if (_places.length > 0) {
      debugger;

      let currentPlaceIndex = -1;

      console.log("hotspot: ", hotspot.Name, "place count", _places.length, "_days", _days, _places);

      _days.forEach(_day => {

        console.log("_day", _day);

        var userDailyVisitCount = 1;

        var daysLoopCount = 0;

        debugger;

        while (storeCount >= userDailyVisitCount) {

          var nextPlace = _places[++currentPlaceIndex % _places.length]

          var _fromDate = dateFormatter(_day);

          //if (!_.some(schedule, { "Place ID": nextPlace["ID"] }))
          {
            hotspotsSchedule.push({
              "Place ID": nextPlace["ID"],
              "Representative ID": hotspot["ID"],
              "From date": _fromDate,
              "Repeat every (x) weeks": "0",
              "Visit time": "",
              //"Visit time": "06:00",3
              //"Visit time": "Any time",
              "End date": "",
              "Duration (in minutes)": "",
              "Visit note": "",
              "Task Group ID": nextPlace["ID"],
              "Task type": "Form",
              "Task Description": "ALL IN ONE",
              ...isDebug && {
                "ZONE": nextPlace["Territory"],
                "ADDRESS": nextPlace["Street Address"],
                "ZIP": nextPlace["ZIP"],
                "City": nextPlace["City"],
                "State": nextPlace["State"],
                "Gps latitude": nextPlace["Gps latitude"],
                "Gps longitude": nextPlace["Gps longitude"],
                "LAST VISIT": nextPlace["Last check-in date"],
                "Hotspot": hotspot["Name"],
                "Sim Delivered": nextPlace["Sim Delivered"],
                "Store Type": nextPlace["Type"]
              }
            });

            //_places = _.remove(_places, { "ID": nextPlace["ID"] });

            userDailyVisitCount++;

            if (_places.length < userDailyVisitCount) {
              break;
            }
          }

        }
      })
    }

    schedule.push(...hotspotsSchedule);

    if (isIndividual) {
      downloadExcel([{ jsonData: hotspotsSchedule, worksheetName: "Sheet1" }], hotspot["Name"] + (isDebug ? "_debug" : ""));
      setTimeout(() => { console.log("Waiting for next file download"); }, 500);
    }
  })

  console.log("schedule", schedule);
  if (!isIndividual) {
    downloadExcel([{ jsonData: schedule, worksheetName: "Sheet1" }], "Schedule" + (isDebug ? "_debug" : ""));
  }
}

const genereateFixedZones = (allStores, kmlFile, activeZones) => new Promise((res, rej) => {
  generatePlaceCategories(allStores);
  let _storesToMove = [];
  let _storesDontHaveAnyZone = [];
  let _mappedZones = [];

  _.forEach(allStores, (store) => {
    let _store = structuredClone(store);
    let _storeType = _store.type;
    _.forEach(kmlFile.features, (feature) => {
      let type = feature.type;
      let properties = feature.properties;
      let geometry = feature.geometry;

      let name = properties.name.trim();

      let zoneDetails = _.find(activeZones, { "Sub wholesaler Id": name });

      if (!zoneDetails) {
        zoneDetails = {
          "Wholesaler Id": "Unknown Wholesaler Id",
          "Branch Office Id": "Unknown Branch Office Id",
          "Sub wholesaler Id": name
        }
      }

      _mappedZones.push(zoneDetails);

      if (!_.some(_storesToMove, { "ID": _store.ID })) {
        if (zoneDetails && geometry && geometry.coordinates && geometry.coordinates[0]) {

          let areaSQ = calculatePolygonArea(geometry.coordinates[0]);

          properties.description = areaSQ.toFixed(2) + " sq";

          zoneDetails["sq mi of area"] = areaSQ.toFixed(2);

          let storePoint = [_store["Gps longitude"], _store["Gps latitude"]];

          let robustPointInPolygon = classifyPoint(geometry.coordinates[0], storePoint);

          if (robustPointInPolygon < 1) {

            let _newTerritory = zoneDetails["Wholesaler Id"] + ">" + zoneDetails["Branch Office Id"] + ">" + zoneDetails["Sub wholesaler Id"];
            let _newTag;

            if (_storeType === "META") {
              _newTag = "META-" + zoneDetails["Branch Office Id"];
            }
            else if (_storeType === "Custom") {
              _newTag = "1. OPEN NEW STORE"
            }
            else if (_storeType === "Open") {
              _newTag = "1. OPEN NEW STORE"
            }
            else if (_storeType === "DD") {
              _newTag = zoneDetails["Branch Office Id"];
            }
            else if (_storeType === "7ELEVEN") {
              _newTag = "LMUS-PF-" + zoneDetails["Branch Office Id"];
              _newTerritory = zoneDetails["PF Category"] + ">" + _newTag + ">" + zoneDetails["PF Alias"];
            }

            if (_store.Territory != _newTerritory) {
              _store.Territory = _newTerritory;
              _store.Tags = _newTag;
              _storesToMove.push(_store);
            } else {

            }
          }

        }
      }

    })

  });

  if (_storesToMove) {
    debugger;
    // let ddsToMove = _storesToMove.filter(function (_storeToMove) {
    //   return _storeToMove.type.some(function (_type) {
    //     return 0 === _type.indexOf("");
    //   });
    // });


    var ddsToMove = _.filter(_storesToMove, s => s.type == "DD" || _.startsWith(s.type, "Others"));

    let dsmUpdate = [];
    _.forEach(ddsToMove, _ddToMove => {
      let originalDD = _.find(allStores, { "ID": _ddToMove.ID });

      if (_ddToMove.Territory?.split(">").pop() != originalDD.Territory?.split(">").pop()) {
        //debugger;
        /*
        FROMWholesalerID
        FROMBranchofficeID
        FROMSubwholesalerID
        FROMRetailerID
        TOWholesalerID
        TOBranchofficeID
        TOSubwholesalerID
        */
        dsmUpdate.push({
          "FROMWholesalerID": "",
          "FROMBranchofficeID": "",
          "FROMSubwholesalerID": originalDD.Territory?.split(">").pop(),
          "FROMRetailerID": _ddToMove["DSM Attributes.RetailerId"] || _ddToMove.ID,
          "TOWholesalerID": "",
          "TOBranchofficeID": _ddToMove.Tags,
          "TOSubwholesalerID": _ddToMove.Territory?.split(">").pop(),
        })
      }
    })


    debugger;

    if (_mappedZones && _mappedZones.length > 0) {
      debugger;
      _mappedZones = _.uniqBy(_mappedZones, "Sub wholesaler Id");
      debugger;

      downloadExcel([{ jsonData: _mappedZones, worksheetName: "Sheet1" }], "MappedZones", "xlsx");

      let _unmappedZones = [];

      _.forEach(activeZones, _zone => {
        if (!_.some(_mappedZones, { "Sub wholesaler Id": _zone["Sub wholesaler Id"] })) {
          _unmappedZones.push(_zone);
        }

      });
      if (_unmappedZones && _unmappedZones.length > 0) {
        downloadExcel([{ jsonData: _unmappedZones, worksheetName: "Sheet1" }], "Unmapped Zones", "xlsx");
      }
    }

    if (_storesDontHaveAnyZone && _storesDontHaveAnyZone.length > 0) {
      downloadExcel([{ jsonData: _storesDontHaveAnyZone, worksheetName: "Sheet1" }], "StoresDontHaveAnyZone", "xlsx");
    }

    // debugger;
    if (_storesToMove && _storesToMove.length > 0) {
      removeUnnecessaryColumnsInRepslyPlaces(_storesToMove);
      downloadExcel([{ jsonData: _storesToMove, worksheetName: "Sheet1" }], "Zone Correction", "xlsx");
    }
    else {
      alert("No store to move!");
    }
    debugger;
    if (dsmUpdate && dsmUpdate.length > 0) {
      downloadExcel([{ jsonData: dsmUpdate, worksheetName: "Sheet1" }], "DSM Zone Update", "csv");
    } else {
      alert("DSM side is good!");
    }
  }
  res(true);
})

const generate3rdPartyMapping = (representatives, activeZones, thirdPartyMapping, mapAllArea) => {
  debugger;
  let mappingFromRepAssignments = [];

  let usersCanSubmitSIM = _.filter(representatives, function (o) {
    let _note = o["Note"].toLowerCase().trim();
    let _designation = o["Designation"].toLowerCase().trim();

    return (
      (
        _note === "marketing rep" ||
        _note === "sales marketing rep" ||
        _note === "lead sales rep" ||
        _note === "team lead" ||
        _note === "area manager" ||
        _note === "area sales manager" ||
        _note === "regional sales manager" ||
        _note === "hotspot manager" ||
        _note === "hotspot manager (admin)" ||
        _note === "head of hotspot usa" ||
        _designation === "sales marketing rep" ||
        _designation === "area manager" ||
        _designation === "team lead"
      ) && o["Active"] === "True"
    )
  }
  );
  _.forEach(usersCanSubmitSIM, _rep => {
    debugger;

    let repZones = getRepZones(activeZones, _rep);

    repZones.forEach(zone => {

      let zoneInfo = _.find(activeZones, { "Sub wholesaler Id": zone });

      if (zoneInfo) {
        //let wholesalerId = zoneInfo["Wholesaler Id"];
        //let branchOfficeId = zoneInfo["Branch Office Id"];

        let alreadyMapped = _.find(thirdPartyMapping, e => {
          return e["Thirdparty ID"].toLowerCase() === _rep.Email.toLowerCase() && e["Hotspot ID"].toLowerCase() === zone.toLowerCase()
        });

        let alreadyAdded = _.find(mappingFromRepAssignments, e => {
          return e["THIRDPARTY_ID"].toLowerCase() === _rep.Email.toLowerCase() && e["HOTSPOT_ID"].toLowerCase() === zone.toLowerCase()
        });


        if (!alreadyAdded && !alreadyMapped) {
          debugger;
          mappingFromRepAssignments.push({
            "THIRDPARTY_ID": _rep.Email,
            //"WholeSaler ID": wholesalerId,
            //"Branchoffice Id": branchOfficeId,
            "HOTSPOT_ID": zone
          });
        }
      } else {
        //alert("'" + zone + "' is not found in active zones sheet");
        console.log("'" + zone + "' is not found in active zones sheet, check '" + _rep.Email + "'s mappings");
      }

    })
  })
  debugger;
  if (!_.isEmpty(thirdPartyMapping)) {

    downloadExcel([{ jsonData: mappingFromRepAssignments, worksheetName: "Sheet1" }], "Mapping", "csv");
  }

  //Thirdparty ID	WholeSaler ID	Branchoffice Id	Hotspot ID
  //console.log("will be");
}

const generatePlaceCategories = (allStores, stripZoneNames, generateRegions) => {

  _.forEach(allStores, store => {

    let _id = store["Place ID"] || store.ID;

    if (generateRegions) {
      store.Region = store.Territory.split(">")[0];
    }

    if (stripZoneNames) {
      store.Zone = store.Territory.split(">").pop();
    }

    if (store["DSM Attributes.RetailerId"] !== null && _.startsWith(store["DSM Attributes.RetailerId"], "DD-")) {
      store.type = "DD";
    }
    else if (_.startsWith(_id, "TM-")) {
      store.type = "TELE";
    }
    else if (_.startsWith(_id, "ST-")) {
      store.type = "STALL";
    }
    else if (_.startsWith(_id, "Open-")) {
      store.type = "Open";
    }
    else if (_.startsWith(_id, "OFFICE")) {
      store.type = "OFFICE";
    }
    else if (_.startsWith(_id, "7ELEVEN")) {
      store.type = "7ELEVEN";
    }
    else if (_.startsWith(_id, "LMUS-META")) {
      store.type = "META";
    }
    else if (_.startsWith(_id, "WS.")) {
      store.type = "WS";
    }
    else if (_id.length == 8 && /^\d+$/.test(_id)) {
      store.type = "Custom";
    }
    else {
      store.type = "Others"
    }

  });
}

const generateAndDownloadCategorizedPlaces = (allStores, dontIncludeOffices, stripZoneNames, generateRegions) => {

  generatePlaceCategories(allStores, stripZoneNames, generateRegions);

  let storesToDownload = allStores;

  if (dontIncludeOffices) {
    storesToDownload = _.reject(allStores, { "type": "OFFICE" });
  }

  downloadExcel([{ jsonData: storesToDownload, worksheetName: "Sheet1" }], "CategorizedPlaces", "xlsx");
}

const generateDownloadComissionList = (representatives, activeZones, showEmails, allStores, includeZoneDetails) => {
  let assignmentList = generateAssignmentList(representatives, activeZones, showEmails, allStores, includeZoneDetails);



}

const generateDownloadAssignmentList = (representatives, activeZones, showEmails, allStores, includeZoneDetails) => {

  let assignmentList = generateAssignmentList(representatives, activeZones, showEmails, allStores, includeZoneDetails);

  downloadExcel(assignmentList, "USA Hotspot Assignments", "xlsx", true);
}

const generateAssignmentList = (representatives, activeZones, showEmails, allStores, includeZoneDetails) => {

  let _activeZones = structuredClone(activeZones);

  let _activeReps = _.filter(representatives, { "Active": "True" });
  let _notAssignedReps = structuredClone(_activeReps);

  let _notAssignedAreaManagers = [];
  let _notAssignedMarketingReps = [];
  let _notAssignedRegionalManagers = [];
  let _notAssignedTeamLeads = [];
  let _activeBranding = [];

  _activeZones.forEach(_zone => {

    let _territory = _zone["Sub wholesaler Id"];
    let _branchOfficeName = _zone["Branch Office Id"];
    let _wholesalerId = _zone["Wholesaler Id"];
    let isATeleZone = _territory.toLowerCase().indexOf("-tele") > 0;

    debugger;
    let _ddCount = allStores ? _.filter(allStores, { Territory: _wholesalerId + ">" + _branchOfficeName + ">" + _territory, "DSM Attributes.Updated by DSM": "yes" }).length : "#N/A"
    debugger;
    let _marketingReps = [];
    let _teamLeads = [];
    let _areaManagers = [];
    let _regionalManagers = [];

    _activeReps.forEach(_rep => {

      let _note = _rep["Note"].toLowerCase().trim();
      let _designation = _rep["Designation"].toLowerCase().trim();
      let repTerritories = getRepZones(activeZones, _rep);

      let _visibleData = showEmails ? _rep.Email : _rep.Name;

      if (repTerritories.includes(_territory)) {
        debugger;

        if (!isATeleZone && _designation == "branding / poster rep" || _note == "branding / poster rep") {

          if (!_.some(_activeBranding, { "Branch Office Id": _branchOfficeName, "Branding / Poster Rep": _visibleData })) {
            _activeBranding.push({ "Wholesaler Id": _wholesalerId, "Branch Office Id": _branchOfficeName, "Branding / Poster Rep": _visibleData });
          }
          _.remove(_notAssignedReps, { "ID": _rep.ID });
        }
        if (!isATeleZone && _designation == "sales marketing rep" || _note == "sales marketing rep") {
          _marketingReps.push(_visibleData);
          _.remove(_notAssignedReps, { "ID": _rep.ID });
        }
        else if (!isATeleZone && _designation == "team lead" || _note == "team lead" || _note == "lead sales rep") {
          _teamLeads.push(_visibleData)
          _.remove(_notAssignedReps, { "ID": _rep.ID });
        }
        else if (!isATeleZone && _designation == "area manager" || _designation == "area sales manager" || _note == "area manager" || _note == "area sales manager") {
          _areaManagers.push(_visibleData)
          _.remove(_notAssignedReps, { "ID": _rep.ID });
        }
        else if (_designation == "regional manager" || _designation == "regional sales manager" || _note == "regional manager" || _note == "regional sales manager") {
          _regionalManagers.push(_visibleData)
          _.remove(_notAssignedReps, { "ID": _rep.ID });
        }
      }
    })

    _zone["DD Count"] = _ddCount;
    _zone["Sales Marketing Rep"] = _marketingReps.join(", ");
    _zone["Team Lead"] = _teamLeads.join(", ");
    _zone["Area Manager"] = _areaManagers.join(", ");
    _zone["Regional Manager"] = _regionalManagers.join(", ");
    _zone["Head of Hotspot"] = "";
  })

  if (_notAssignedMarketingReps && _notAssignedMarketingReps.length > 0) {
    _notAssignedMarketingReps.forEach(_e => _activeZones.push({ "Sales Marketing Rep": _e["Name"] }));
  }
  if (_notAssignedTeamLeads && _notAssignedTeamLeads.length > 0) {
    _notAssignedMarketingReps.forEach(_e => _activeZones.push({ "Team Lead": _e["Name"] }));
  }
  if (_notAssignedAreaManagers && _notAssignedAreaManagers.length > 0) {
    _notAssignedMarketingReps.forEach(_e => _activeZones.push({ "Area Sales Manager": _e["Name"] }));
  }
  if (_notAssignedRegionalManagers && _notAssignedRegionalManagers.length > 0) {
    _notAssignedMarketingReps.forEach(_e => _activeZones.push({ "Regional Sales Manager": _e["Name"] }));
  }

  _notAssignedReps = _.sortBy(_notAssignedReps, ["Note", "Designation", "Territories", "Name"]);

  let file = [
    {
      jsonData: _activeZones,
      worksheetName: "Sales Team Assignments"
    },
    {
      jsonData: _activeBranding,
      worksheetName: "Branding Team Assignments"
    },
    {
      jsonData: _notAssignedReps,
      worksheetName: "Not Assigned"
    }
  ]

  return file;
}

const generateDownloadFixedTagsList = (allStores, activeZones) => {

  const _fixedStores = [];
  const _allStores = structuredClone(allStores);

  generatePlaceCategories(_allStores);

  _.forEach(_allStores, _store => {
    debugger;
    try {
      if (_store.type == "DD" || _store.type == "Open" || _store.type == "META" || _store.type == "Custom" || _store.type == "Others") {
        let storeZone = _store.Territory?.split(">").pop();
        let correctZoneInfo = _.find(activeZones, { "Sub wholesaler Id": storeZone });
        debugger;
        let correctTag = "";
        switch (_store.type) {
          case "DD":
            correctTag = correctZoneInfo["Branch Office Id"];
            break;
          case "Custom":
            correctTag = "1. OPEN NEW STORE";
            break;
          case "Open":
            correctTag = "1. OPEN NEW STORE";
            break;
          case "META":
            correctTag = "META-" + correctZoneInfo["Branch Office Id"];
            break;
          case "Others":
            correctTag = correctZoneInfo["Branch Office Id"];
            break;
        }
        debugger;
        if (correctTag != _store.Tags) {
          debugger;
          _store.Tags = correctTag;
          _fixedStores.push(_store);
        }
      }
    }
    catch (ex) {
      console.log(ex, _store);
    }
  })

  removeUnnecessaryColumnsInRepslyPlaces(_fixedStores);

  downloadExcel([{ jsonData: _fixedStores, worksheetName: "Sheet1" }], "Repsly Fixed Store Tags");
}

const generateDownloadDuplicatedStoreList = (allStores, categoriesToRemove) => {

  generatePlaceCategories(allStores);

  var foundToRemove = [];

  // var allDD = _.filter(allStores, s => _.startsWith(s.ID, "DD-"));
  // var nonDD = _.filter(allStores, s => !_.startsWith(s.ID, "DD-"));

  var allDD = _.filter(allStores, { "type": "DD" });
  var nonDD = _.filter(allStores, s => s.type === "Custom" || s.type === "Open" || s.type === "Meta" || s.type === "Others");


  _.forEach(allDD, dd => {
    var foundDuplicates = _.filter(nonDD, _nonDd =>
      (_nonDd["Gps latitude"] === dd["Gps latitude"] &&
        _nonDd["Gps longitude"] === dd["Gps longitude"]) && (
        _nonDd["Gps latitude"] != "0" ||
        _nonDd["Gps longitude"] != "0"
      )
    );
    if (foundDuplicates) {
      _.forEach(foundDuplicates, _found => {
        if (!_.some(foundToRemove, { "ID": _found.ID })) {
          _found.DDVersion = dd["DSM Attributes.RetailerId"];
          foundToRemove.push(_found);
        }
      })
    }
  });

  if (foundToRemove && foundToRemove.length > 0) {
    for (let index = 0; index < foundToRemove.length; index++) {
      foundToRemove[index].Active = false;
      foundToRemove[index].Tags = "LMUS-HS-CLOSEDSHOP";
      foundToRemove[index].Note = "DD version is created as '" + foundToRemove[index].DDVersion + "'";

      delete foundToRemove[index]["Representative name"];
    }

    removeUnnecessaryColumnsInRepslyPlaces(foundToRemove);
    downloadExcel([{ jsonData: foundToRemove, worksheetName: "Sheet1" }], "remove");
  }
  else {
    alert("No duplicate found.");
  }

}

const prepareMissingStoresAndDownload = (dsmExport, allStores, activeZones, getOnlyDD) => new Promise((res, rej) => {

  debugger;
  var missingStores = [];
  var closedStores = [];
  var storesToAddRepsly = [];
  var faultyRecords = [];

  _.filter(dsmExport, s => (getOnlyDD ? _.startsWith(s["Retailer Id"], "DD-") : true)).forEach(
    store => {
      if (!_.find(allStores, { ID: store["Retailer Id"] }) && !_.find(allStores, s => s["DSM Attributes.RetailerId"] == store["Retailer Id"])) {
        missingStores.push(store);
      }
    });

  missingStores.forEach(missingStore => {

    let correctZone = _.find(activeZones, a => a["Sub wholesaler Id"] === missingStore["Sub wholesaler Id"]);
    let faultyRecord = false;
    let taxId = missingStore.TaxID || missingStore["Business Registration Number"] || missingStore["Social Security Number"];
    let closedStore = missingStore.status != "Active" || missingStore["Sub wholesaler Id"]?.indexOf("CLOSED") > -1;

    /*
    activeZones.forEach(activeZone => {
      if (activeZone["Sub wholesaler Id"] === missingStore["Sub wholesaler Id"]) {
        correctZone = activeZone;
      }
    })
    */

    let tag = closedStore ? "LMUS-HS-CLOSEDSHOP" : correctZone ? correctZone["Branch Office Id"] : "UNKNOWN ZONE";

    let noTax = taxId === null;
    let noDefinedZone = correctZone === undefined;

    faultyRecord = (noTax || closedStore || noDefinedZone);

    if (!faultyRecord) {

      let territory = closedStore ? missingStore["Sub wholesaler Id"] : (correctZone["Wholesaler Id"] + ">" + correctZone["Branch Office Id"] + ">" + correctZone["Sub wholesaler Id"]);

      let storeForRepsly = {
        "ID": missingStore["Retailer Id"],
        "Name": missingStore["Shop Name"] + "  (DD)",
        "Active": closedStore ? false : true,
        "Tags": tag,
        "Street Address": missingStore["HouseNo"] + ", " + missingStore["Street"],
        "ZIP": missingStore["Post Code"],
        "City": missingStore["City"],
        "Country": "United States",
        "State": missingStore["state"] || missingStore["State"],
        "Email": missingStore["Email"],
        "Phone": missingStore["Land line Number"],
        "Cell phone": missingStore["Mobile Number"],
        "Contact name": missingStore["Contact Person"],
        "Territory": territory,
        "Additional Info.TAX ID": taxId
      }

      if (closedStore) {
        closedStores.push(storeForRepsly);
      }
      else {
        storesToAddRepsly.push(storeForRepsly)
      }
    }
    else {

      let faults = [];

      if (noTax) {
        faults.push("Tax Id is Missing")
      }
      if (noDefinedZone) {
        faults.push("Unknown Zone Name");
      }
      if (closedStore) {
        faults.push("Inactive Store");
      }

      faultyRecords.push({
        Fault: faults.join(" & "),
        ...missingStore
      })
    }
  })

  console.log("closedStores", closedStores);

  if (!_.isEmpty(storesToAddRepsly)) {
    downloadExcel([{ jsonData: storesToAddRepsly, worksheetName: "Sheet1" }], "New_ImportToRepsly");
  } else {
    alert("No missing stores");
  }
  if (!_.isEmpty(closedStores)) {
    downloadExcel([{ jsonData: closedStores, worksheetName: "Sheet1" }], "Closed_ImportToRepsly");
  }
  if (!_.isEmpty(faultyRecords)) {
    downloadExcel([{ jsonData: faultyRecords, worksheetName: "Sheet1" }], "FaultyRecords_DSM");
  }
  res(true);
})

//Before DSM Integration
const prepareMissingStoresAndDownloadBackup = (dsmExport, allStores, activeZones, getOnlyDD) => new Promise((res, rej) => {

  debugger;
  var missingStores = [];
  var closedStores = [];
  var storesToAddRepsly = [];
  var faultyRecords = [];

  _.filter(dsmExport, s => (getOnlyDD ? _.startsWith(s["Retailer Id"], "DD-") : true)).forEach(
    store => {
      if (!_.find(allStores, { ID: store["Retailer Id"] })) {
        missingStores.push(store);
      }
    });

  missingStores.forEach(missingStore => {

    let correctZone = _.find(activeZones, a => a["Sub wholesaler Id"] === missingStore["Sub wholesaler Id"]);
    let faultyRecord = false;
    let taxId = missingStore.TaxID || missingStore["Business Registration Number"] || missingStore["Social Security Number"];
    let closedStore = missingStore.status != "Active" || missingStore["Sub wholesaler Id"]?.indexOf("CLOSED") > -1;

    /*
    activeZones.forEach(activeZone => {
      if (activeZone["Sub wholesaler Id"] === missingStore["Sub wholesaler Id"]) {
        correctZone = activeZone;
      }
    })
    */

    let tag = closedStore ? "LMUS-HS-CLOSEDSHOP" : correctZone ? correctZone["Branch Office Id"] : "UNKNOWN ZONE";

    let noTax = taxId === null;
    let noDefinedZone = correctZone === undefined;

    faultyRecord = (noTax || closedStore || noDefinedZone);

    if (!faultyRecord) {

      let territory = closedStore ? missingStore["Sub wholesaler Id"] : (correctZone["Repsly Category"] + ">" + correctZone["Sub wholesaler Id"]);

      let storeForRepsly = {
        "ID": missingStore["Retailer Id"],
        "Name": missingStore["Shop Name"] + "  (DD)",
        "Active": closedStore ? false : true,
        "Tags": tag,
        "Street Address": missingStore["HouseNo"] + ", " + missingStore["Street"],
        "ZIP": missingStore["Post Code"],
        "City": missingStore["City"],
        "Country": "United States",
        "State": missingStore["state"] || missingStore["State"],
        "Email": missingStore["Email"],
        "Phone": missingStore["Land line Number"],
        "Cell phone": missingStore["Mobile Number"],
        "Contact name": missingStore["Contact Person"],
        "Territory": territory,
        "Additional Info.TAX ID": taxId
      }

      if (closedStore) {
        closedStores.push(storeForRepsly);
      }
      else {
        storesToAddRepsly.push(storeForRepsly)
      }
    }
    else {

      let faults = [];

      if (noTax) {
        faults.push("Tax Id is Missing")
      }
      if (noDefinedZone) {
        faults.push("Unknown Zone Name");
      }
      if (closedStore) {
        faults.push("Inactive Store");
      }

      faultyRecords.push({
        Fault: faults.join(" & "),
        ...missingStore
      })
    }
  })

  console.log("closedStores", closedStores);

  if (!_.isEmpty(storesToAddRepsly)) {
    downloadExcel([{ jsonData: storesToAddRepsly, worksheetName: "Sheet1" }], "New_ImportToRepsly");
  } else {
    alert("No missing stores");
  }
  if (!_.isEmpty(closedStores)) {
    downloadExcel([{ jsonData: closedStores, worksheetName: "Sheet1" }], "Closed_ImportToRepsly");
  }
  if (!_.isEmpty(faultyRecords)) {
    downloadExcel([{ jsonData: faultyRecords, worksheetName: "Sheet1" }], "FaultyRecords_DSM");
  }
  res(true);
})


const generateMonthlyCommissions = (representatives, activeZones) => {

  let staffList = [];
  let staffListforEric = [];

  let hotspotList = [];
  let leadList = [];
  let areaManagerList = [];
  let stateManagerList = [];
  let headList = [];


  _.forEach(_.filter(representatives, { "Active": "True" }), rep => {

    if (_.includes(rep.Note, "Area Manager") || _.includes(rep.Note, "Area Sales Manager")) {
      let areaManagersZones = getRepZones(activeZones, rep);
      _.forEach(areaManagersZones, _areaManagerZone => {
        areaManagerList.push({ "Zone ID": _areaManagerZone, "Area Manager Name": rep.Name });
      })
    }
    else if (_.includes(rep.Note, "Lead Sales Rep") || _.includes(rep.Note, "Team Lead")) {
      let leadZones = getRepZones(activeZones, rep);
      _.forEach(leadZones, _leadZone => {
        leadList.push({ "AccMgrid": _leadZone, "Hotspot Manager / Lead Name": rep.Name });
      })
    }
    else if (_.includes(rep.Note, "Regional Manager") || _.includes(rep.Note, "Regional Sales Manager")) {
      let regionalZones = getRepZones(activeZones, rep);
      _.forEach(regionalZones, _regionalZone => {
        stateManagerList.push({ "OffMgrid": _regionalZone, "State Manager Name": rep.Name });
      })
    }

  });

  _.forEach(activeZones, zone => {
    headList.push({
      "HotSpot ID": zone["Sub wholesaler Id"],
      "Head of Hotspot Operations Name": ""
    })
  })

  //firat
  staffList.push({ ...leadList, ...areaManagerList, ...stateManagerList, ...headList });

  let commissionFile = [{
    jsonData: hotspotList,
    worksheetName: "Hotspots"
  }, {
    jsonData: leadList,
    worksheetName: "Leads"
  }, {
    jsonData: areaManagerList,
    worksheetName: "Area Managers"
  }, {
    jsonData: stateManagerList,
    worksheetName: "State Managers"
  }, {
    jsonData: headList,
    worksheetName: "Head of Hotspot Operations"
  }]

  if (staffList) {
    downloadExcel(commissionFile, "USA Staff List", "xlsx");
  }
  if (staffListforEric) {
    downloadExcel([{ jsonData: staffListforEric, worksheetName: "Sheet1" }], "Usa Staff List - for Eric", "xlsx");
  }
}

function Main() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(),
    endDate: new Date()
  });
  const [processing, setProcessing] = useState(false);
  const [dateSelected, setDate] = useState(false);
  const [storeCount, setStoreCount] = useState(15);
  const [isDebug, setDebug] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [getOnlyDD, setGetOnlyDD] = useState(true);
  const [scheduleDescription, setScheduleDescription] = useState("ALL IN ONE");
  const [scheduleTaskType, setScheduleTaskType] = useState("Form");
  const [scheduleVisitNote, setScheduleVisitNote] = useState("");
  const [dontIncludeOffices, setDontIncludeOffices] = useState(true);
  const [includeZoneDetails, setIncludeZoneDetails] = useState(false);

  const [stripZoneNames, setStripZoneNames] = useState(true);
  const [generateRegions, setGenerateRegions] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [mapAllArea, setMapAllArea] = useState(false);

  const [activeEmployees, setActiveEmployees] = useState();
  const [monthlyActivitiesForIncentive, setMonthlyActivitiesForIncentive] = useState();
  const [monthlyBundleRenewalDetailReport, setMonthlyBundleRenewalDetailReport] = useState();
  const [representatives, setRepresentatives] = useState();
  const [thirdPartyMapping, setThirdPartyMapping] = useState();
  const [allStores, setAllStores] = useState();
  const [dsmExport, setDsmExport] = useState();
  const [activeZones, setActiveZones] = useState();
  const [allInOneForm, setAllInOneForm] = useState();
  const [kmlFile, setKmlFile] = useState();
  const [showEmails, setShowEmails] = useState(false);
  const [aioSubmissions, setAioSubmissions] = useState();
  const [activations, setActvations] = useState();
  const [scheduleManagerList, setScheduleManagerList] = useState([]);

  const scheduleManagerOnUpdate = (updatedStore) => {

    console.log("Main", updatedStore);

    const currentStoreIndex = scheduleManagerList.findIndex((_store) => _store.ID === updatedStore.ID);

    let storeToUpdate = Object.assign({}, scheduleManagerList[currentStoreIndex]);

    storeToUpdate = updatedStore;

    const newTodos = scheduleManagerList.slice();

    newTodos[currentStoreIndex] = storeToUpdate;

    setScheduleManagerList(newTodos);
  }

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  const RepresentativePicker = (props) => {
    return <>
      <CustomFileUploader disabled={props.disabled} label="Repsly: Users" file={representatives} setFile={setRepresentatives} accept=".xls, .xlsx" fileHandler={e => {
        return readUploadedExcelFile(e).then(value => {
          setRepresentatives(value);
        })
      }} />
      <Link
        to="https://user.repsly.com/users"
        target="_blank">
        <Download style={{ paddingTop: "10px" }} color='primary' />
      </Link>

    </>
  }

  const PlacesPicker = (props) => {
    return <>
      <CustomFileUploader disabled={props.disabled} label="Repsly: Places" file={allStores} setFile={setAllStores} accept=".xls, .xlsx" fileHandler={e => {
        return readUploadedExcelFile(e).then(value => {
          setAllStores(value);
          setScheduleManagerList(value);
        })
      }} />
      <Link
        to="https://user.repsly.com/places"
        target="_blank">
        <Download style={{ paddingTop: "10px" }} color='primary' />
      </Link>
    </>
  }

  const ActiveZonesPicker = (props) => {
    return <>
      <CustomFileUploader disabled={props.disabled} label="Active Zones List" file={activeZones} setFile={setActiveZones} accept=".xls, .xlsx" fileHandler={e => {
        return readUploadedExcelFile(e).then(value => {
          setActiveZones(value)
        })
      }} />
    </>
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <div className="app-content">
          {/* 
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }} component="span">Import New/Missing Stores</Typography>
              <Typography sx={{ color: 'text.secondary' }} component="span">
                from DSM to Repsly
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='main'>
                <div>

                  <CustomFileUploader label="Daily DSM Export (DD's)" file={dsmExport} setFile={setDsmExport} accept=".xls, .xlsx" fileHandler={e => {
                    return readUploadedExcelFile(e).then(value => {
                      setDsmExport(value)
                    })
                  }} />
                  <Link
                    to="https://dsm.lycamobile.us/Shop/ShopReport.aspx"
                    target="_blank">
                    <Download style={{ paddingTop: "10px" }} color='primary' />
                  </Link>

                  <br />
                  <br />
                  <PlacesPicker />
                  <br />
                  <br />
                  <ActiveZonesPicker />
                </div>
              </div>
              <br />
              <FormGroup>
                <FormControlLabel control={<Switch
                  checked={getOnlyDD}
                  onChange={e => setGetOnlyDD(!getOnlyDD)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />} label="DD's only" />
              </FormGroup>

              <div className='footer'>

                <LoadingButton
                  disabled={Boolean(!!(allStores && dsmExport && activeZones))}
                  onClick={() => {
                    setProcessing(true);
                    prepareMissingStoresAndDownload(dsmExport, allStores, activeZones, getOnlyDD)
                      .then(() => {
                        setProcessing(false)
                      })
                  }}
                  loading={processing}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  <span>Download Missing Stores</span>
                </LoadingButton>
              </div>

            </AccordionDetails>
          </Accordion> */}

          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }} component="span">Map/Zone Management</Typography>
              <Typography sx={{ color: 'text.secondary' }} component="span">
                For zone corrrections and management
              </Typography>

            </AccordionSummary>
            <AccordionDetails>
              <div className='main'>
                <div>
                  <CustomFileUploader label="KML File" file={kmlFile} setFile={setKmlFile} accept=".kml" fileHandler={e => {
                    return readUploadedKMLFile(e).then(value => {
                      setKmlFile(value)
                    })
                  }} />
                  <Link
                    to="https://www.google.com/maps/d/edit?mid=1mBCTHOETAGOettfIE6sNOCp2KqqHEEU&usp=sharing"
                    target="_blank">
                    <Download style={{ paddingTop: "10px" }} color='primary' />
                  </Link>
                  <br />
                  <br />
                  <PlacesPicker />
                  <br />
                  <br />
                  <ActiveZonesPicker />
                </div>
              </div>
              <br />
              <div className='footer'>


                <LoadingButton
                  disabled={!(allStores && kmlFile && activeZones)}
                  onClick={() => {
                    setProcessing(true);
                    genereateFixedZones(allStores, kmlFile, activeZones).then(() => {
                      setProcessing(false)
                    })
                  }}
                  loading={processing}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  <span>Download Aligned Stores</span>
                </LoadingButton>


                {/* 
                <Button variant="contained" disabled={processing || !(allStores && kmlFile && activeZones)} onClick={() => {
                  setProcessing(true);
                  genereateFixedZones(allStores, kmlFile, activeZones).then(() => {
                    setProcessing(false)
                  })
                }}>Download Aligned Stores</Button> */}
              </div>

            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }} component="span">
                Schedules
              </Typography>
              <Typography sx={{ color: 'text.secondary' }} component="span">Prepare daily routes and schedule</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='main'>
                <div>

                  <ActiveZonesPicker />

                  <br />
                  <br />

                  <PlacesPicker disabled={!representatives} />


                  <br />
                  <br />

                  <RepresentativePicker disabled={!activeZones} />

                  <br />
                  <br />

                  <CustomFileUploader disabled={!allStores} label="Repsly: AIO Form Submissions" file={aioSubmissions} setFile={setAioSubmissions} accept=".xls, .xlsx" fileHandler={e => {
                    return readUploadedExcelFile(e).then(value => {
                      setAioSubmissions(value)
                    })
                  }} />
                  <Link
                    to="https://user.repsly.com/reports/form/e5f610b4-b047-4d28-8975-86244dd247c4#submissions"
                    target="_blank">
                    <Download style={{ paddingTop: "10px" }} color='primary' />
                  </Link>

                  <br />
                  <br />


                  <CustomFileUploader disabled={!allStores} label="DSM: Activations" file={activations} setFile={setActvations} accept=".xls, .xlsx" fileHandler={e => {
                    return readUploadedExcelFile(e).then(value => {
                      setActvations(value)
                    })
                  }} />

                  <br /><br />
                  <label> Select Date Range: </label>
                  <Calendar
                    style={{ backgroundColor: "black" }}
                    onChange={
                      (e) => {
                        setSelectedDates({
                          startDate: e[0],
                          endDate: e[1]
                        });
                        setDate(true);
                      }
                    }
                    selectRange={true} />

                  <br />
                  <br />

                  <ScheduleManager
                    users={representatives}
                    places={scheduleManagerList}
                    activeZones={activeZones}
                    startDate={selectedDates.startDate}
                    endDate={selectedDates.endDate}
                    onUpdate={scheduleManagerOnUpdate} />

                  {/* <div>




                    <FormControl>
                      <InputLabel>Task Type</InputLabel>
                      <Select
                        value={scheduleTaskType}
                        label="Task Type"
                        onChange={e => setScheduleTaskType(e.target.value)}
                      >
                        <MenuItem value={"Form"}>Form</MenuItem>
                        <MenuItem value={"Photo"}>Photo</MenuItem>
                        <MenuItem value={"Audit"}>Audit</MenuItem>
                        <MenuItem value={"Sales document"}>Sales document</MenuItem>

                      </Select>
                    </FormControl>
                    <br /><br />
                    <FormControl disabled={true}>
                      <InputLabel>Visit Balance</InputLabel>
                      <Select
                        value={"Form"}
                        label="Task Type"
                      >
                        <MenuItem value={"Form"}>Form</MenuItem>
                        <MenuItem value={"Photo"}>Photo</MenuItem>
                        <MenuItem value={"Audit"}>Audit</MenuItem>
                        <MenuItem value={"Sales document"}>Sales document</MenuItem>

                      </Select>
                    </FormControl>
                    <br /><br />
                    <TextField label="Description"
                      variant="outlined"
                      value={scheduleDescription}
                      onChange={e => setScheduleDescription(e.target.value)}
                    />
                    <br /><br />
                    <TextField label="Visit Notes"
                      variant="outlined"
                      value={scheduleVisitNote}
                      onChange={e => setScheduleVisitNote(e.target.value)}
                    />
                    <br /><br />
                    <TextField
                      id="outlined-number"
                      label="Visit Per Day"
                      type="number" defaultValue={storeCount}
                      onChange={
                        e => setStoreCount(e.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />


                  </div> */}

                  <FormGroup>
                    <FormControlLabel control={<Switch
                      checked={isDebug}
                      onChange={e => setDebug(!isDebug)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Add Debug Columns" />

                    <FormControlLabel control={<Switch
                      checked={isIndividual}
                      onChange={e => setIsIndividual(!isIndividual)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Individual Download" />

                  </FormGroup>

                </div>
              </div>
              <div className='footer'>
                {dateSelected ? <div> from "<strong>{(selectedDates.startDate.getMonth() + 1) + '/' + selectedDates.startDate.getDate() + '/' + selectedDates.startDate.getFullYear()}</strong>"  to "<strong>{(selectedDates.endDate.getMonth() + 1) + '/' + selectedDates.endDate.getDate() + '/' + selectedDates.endDate.getFullYear()}</strong>" </div> : ""}
                <Button variant="contained" disabled={processing || !dateSelected} onClick={() => { prepareReportAndDownloadSchedule(representatives, storeCount, selectedDates.startDate, selectedDates.endDate, isDebug, isIndividual) }} > Download Schedule </Button>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }} component="span">Incentives</Typography>
              <Typography sx={{ color: 'text.secondary' }} component="span">
                Prepare monthly commissions
              </Typography>

            </AccordionSummary>
            <AccordionDetails>
              <div className='main'>
                <div>
                  <CustomFileUploader label="DSM: Bundle Renewal Detail Report (Whole Month)" file={monthlyBundleRenewalDetailReport} setFile={setMonthlyBundleRenewalDetailReport} accept=".xls, .xlsx" fileHandler={e => {
                    return readUploadedExcelFile(e).then(value => {
                      setMonthlyBundleRenewalDetailReport(value)
                    })
                  }} />

                  <Link
                    to="https://dsm.lycamobile.us/Reports/BundleRenewalReportforAll.aspx"
                    target="_blank">
                    <Download style={{ paddingTop: "10px" }} color='primary' />
                  </Link>
                  <br />
                  <br />
                  <CustomFileUploader label="Repsly: Visits with Place Data (Whole Month)" file={monthlyActivitiesForIncentive} setFile={setMonthlyActivitiesForIncentive} accept=".xls, .xlsx" fileHandler={e => {
                    return readUploadedExcelFile(e).then(value => {
                      setMonthlyActivitiesForIncentive(value)
                    })
                  }} />

                  <Link
                    to="https://user.repsly.com/manage/export"
                    target="_blank">
                    <Download style={{ paddingTop: "10px" }} color='primary' />
                  </Link>
                  <br />
                  <br />
                  <RepresentativePicker />

                  <br /><br />
                  <CustomFileUploader label="Active Employees" file={activeEmployees} setFile={setActiveEmployees} accept=".xls, .xlsx" fileHandler={e => {
                    return readUploadedExcelFile(e).then(value => {
                      setActiveEmployees(value)
                    })
                  }} />

                  <br /><br />
                  <ActiveZonesPicker />

                </div>
              </div>

              <div className='footer'>
                <Button variant="contained" disabled={processing || !(representatives && activeZones)} onClick={() => { generateMonthlyCommissions(representatives, activeZones) }}>Download Monthly Commissions</Button>
              </div>
            </AccordionDetails>
          </Accordion> */}

          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }} component="span">
                Tools
              </Typography>
              <Typography sx={{ color: 'text.secondary' }} component="span">
                Various tools to manage data
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='main'>
                <div>
                  <CustomFileUploader label="DSM: 3rd Party Mapping List" file={thirdPartyMapping} setFile={setThirdPartyMapping} accept=".xls, .xlsx" fileHandler={e => {
                    return readUploadedExcelFile(e).then(value => {
                      setThirdPartyMapping(value)
                    })
                  }} />


                  <Link
                    to="https://dsm.lycamobile.us/Hotspot/SWSThirdpartyMappingEdit.aspx"
                    target="_blank">
                    <Download style={{ paddingTop: "10px" }} color='primary' />
                  </Link>
                  <br /> <br />

                  <RepresentativePicker />

                  <br /> <br />

                  <PlacesPicker />

                  <br /> <br />

                  <ActiveZonesPicker />

                </div>
              </div>
              <div className='footer'>

                <FormGroup>

                  <FormControlLabel control={<Switch
                    disabled={!(allStores && activeZones)}
                    checked={includeZoneDetails} onChange={e => setIncludeZoneDetails(!includeZoneDetails)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="add zone details" />


                  <FormControlLabel control={<Switch
                    disabled={!(allStores && activeZones)}
                    checked={dontIncludeOffices} onChange={e => setDontIncludeOffices(!dontIncludeOffices)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="dontIncludeOffices" />

                  <FormControlLabel control={<Switch
                    disabled={!(allStores && activeZones)}
                    checked={stripZoneNames} onChange={e => setStripZoneNames(!stripZoneNames)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="stripZoneNames" />

                  <FormControlLabel control={<Switch
                    disabled={!(allStores && activeZones)}
                    checked={generateRegions} onChange={e => setGenerateRegions(!generateRegions)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="generateRegions" />


                  <FormControlLabel control={<Switch
                    disabled={!(representatives && activeZones && thirdPartyMapping)}
                    checked={mapAllArea} onChange={e => setMapAllArea(!mapAllArea)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="mapAllArea 3rd party" />

                  <FormControlLabel control={<Switch
                    disabled={!(representatives && activeZones)}
                    checked={showEmails} onChange={e => setShowEmails(!showEmails)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="showEmails" />

                </FormGroup>

                <Button variant="contained" disabled={processing || !(representatives && activeZones && thirdPartyMapping)} onClick={() => { generate3rdPartyMapping(representatives, activeZones, thirdPartyMapping, mapAllArea) }}>Generate 3rd Party Mapping</Button>
                <br />
                <Button variant="contained" disabled={processing || !(allStores && activeZones)} onClick={() => { generateAndDownloadCategorizedPlaces(allStores, dontIncludeOffices, stripZoneNames, generateRegions) }}>Download Categorized Places</Button>
                <br />
                <Button variant="contained" disabled={processing || !(allStores)} onClick={() => { generateDownloadDuplicatedStoreList(allStores) }}>Download Duplicated Stores</Button>
                <br />
                <Button variant="contained" disabled={processing || !(allStores && activeZones)} onClick={() => { generateDownloadFixedTagsList(allStores, activeZones) }}>Download Fixed Store Tags</Button>
                <br />
                <Button variant="contained" disabled={processing || !(representatives && activeZones)} onClick={() => { generateDownloadAssignmentList(representatives, activeZones, showEmails, allStores, includeZoneDetails) }}>Download Assignment List</Button>
                <br />
                <Button variant="contained" disabled={processing || !(representatives && activeZones)} onClick={() => { generateDownloadComissionList(representatives, activeZones, showEmails, allStores, includeZoneDetails) }}>Download Comission List</Button>

              </div>
            </AccordionDetails>
          </Accordion>

        </div>

      </div>
    </ThemeProvider>
  );
}

export default Main;