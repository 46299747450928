import React, { useRef, useState } from "react";
import Box from '@mui/material/Box';
import * as _ from "lodash";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import GoogleMapReact from 'google-map-react';
import zIndex from "@mui/material/styles/zIndex";

const defaultProps = {
    center: {
        lat: 40.736237,
        lng: -74.170270
    },
    zoom: 10
};

const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    console.log("handleApiLoaded", map, maps);
    map.setClickableIcons(false) // Need to call this to disable POIs

};


const ScheduleMap = (props) => {

    console.log("ScheduleMap > day", props.day);

    const [highlightedPlaceId, setHighlightedPlaceId] = React.useState();
    const [expandedPlaceid, setExpandedPlaceId] = React.useState();


    const highlightPlacemark = (placeId, highlighted) => {

        setHighlightedPlaceId(highlighted ? placeId : null);

        //console.log("highlightedPlaceId", highlightedPlaceId);
    }

    const onPlaceMarkClick = (child, expanded) => {

        console.log("onPlaceMarkClick", child);

        setExpandedPlaceId(expanded ? child.store.ID : null);
    }

    const StorePlacemark = ({ store, highlighted, expanded }) => (

        <>

            <div style={{
                borderRadius: '100%',
                borderInline: highlighted ? "5px solid green" : "none",
                background: store.selected ? "Red" : "Orange",
                padding: '8px 8px',
                cursor: "pointer",
                display: 'inline-flex',
                justifyContent: 'center',
                transform: 'translate(-50%, -50%)'
            }}></div>

            {expanded &&
                <div style={{
                    color: "initial",
                    background: "white",
                    border: 'none',
                    boxShadow: "1px 6px 6px rgba(0, 0, 0, 0.4)",
                    borderRadius: "8px",
                    //zIndex: "9999999999",
                    minWidth: "300px"
                }}>
                    <div style={{
                        padding: "5px",
                        fontSize: "15px",
                        fontWeight: "bold",
                        backgroundColor: "#ccc",
                        float: "right",
                        cursor: "pointer",
                    }} onClick={() => { onPlaceMarkClick({ ...store }, false) }}>X</div>

                    <div style={{
                        padding: "8px",
                        cursor: "default",
                        zIndex: "9999999999"
                    }}>

                        <FormGroup>

                            <FormControlLabel control={<Checkbox checked={!!(store.selected)} onChange={() => handleClick(store)} />} label={store.Name} />
                            <strong>{store.ID}</strong>
                            <br />
                            <br />
                            Zone: {store.Territory.split(">").pop()}
                            <br />
                            <br />
                            Last Check-In: {store["Last check-in date"]}


                        </FormGroup>

                    </div>
                </div>
            }


        </>
    )

    const handleClick = (store) => {

        console.log("handleClick", store);

        store.selected = !store.selected;

        props.onSelected(store);
    }

    const clearAllButtonClick = () => {
        if(window.confirm("Are you sure to clear all selected stores for this day?")){
            console.log("day cleared");
        }
        
    }

    const autoOptimizeButtonClick = () => {

    }

    const nextDayButtonClick = ()=>{

    }

    return (
        props.storeList &&
        props.selectedZoneName &&
        <Box sx={{ flexGrow: 1 }}>
            <Grid
                component="div"
                container spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >

                <Grid item xs={6} md={8} height={500} component="div">

                    <GoogleMapReact yesIWantToUseGoogleMapApiInternals
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        options={{
                            gestureHandling: "greedy"
                        }}
                        onChildClick={(key, child) => onPlaceMarkClick(child, true)}
                        onChildMouseEnter={(key, child) => highlightPlacemark(child.store.ID, true)}
                        onChildMouseLeave={(key, child) => highlightPlacemark(child.store.ID, false)}
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >

                        {_.filter(props.storeList, { Territory: props.selectedZoneName }).map((store) => (
                            <StorePlacemark
                                key={store.ID}
                                lat={store["Gps latitude"]}
                                lng={store["Gps longitude"]}
                                store={store}
                                expanded={store.ID == expandedPlaceid}
                                highlighted={store.ID == highlightedPlaceId}
                            />)
                        )}

                    </GoogleMapReact>
                </Grid>
                <Grid item xs={6} md={4} component="div">
                    <Button onClick={autoOptimizeButtonClick}>Auto Optimize</Button>
                    <Button onClick={clearAllButtonClick}>Clear All</Button>
                    <Button onClick={nextDayButtonClick}>Next Day</Button>

                    <List
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 500,
                        }}
                    >

                        {_.filter(props.storeList, { Territory: props.selectedZoneName }).map((store) => (

                            <ListItem
                                key={store.ID}
                                // secondaryAction={
                                //     <IconButton edge="end" aria-label="comments">
                                //         <CommentIcon />
                                //     </IconButton>
                                // }
                                disablePadding
                            >
                                <ListItemButton
                                    key={store.ID}
                                    disableRipple
                                    //role={undefined}
                                    onClick={() => { handleClick(store) }} dense
                                    onMouseEnter={() => highlightPlacemark(store.ID, true)}
                                    onMouseLeave={() => highlightPlacemark(store.ID, false)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            key={store.ID}

                                            edge="start"
                                            checked={store.selected}
                                            inputProps={{ 'aria-labelledby': store.ID }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={store.Name}
                                        primary={store.Name}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    {store.City}
                                                </Typography>
                                                {" — "} {store["Street Address"]} {" — "} {store.ZIP}
                                            </React.Fragment>
                                        }

                                    />

                                </ListItemButton>
                            </ListItem>
                        )
                        )}
                    </List>

                </Grid>
            </Grid>

        </Box>)
}

export default ScheduleMap
